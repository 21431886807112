import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { DataService } from '../data/data.service';
import { CategoryDataService } from '../data/categoryData.service';
import { AddressDataService } from '../data/addressData.service';
import { ListDataService } from '../data/listData.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  deleteMessage: string;
  retrace: number;

  constructor(
    private router: Router,
    private profile: ProfileService,
    private data: DataService,
    private category: CategoryDataService,
    private address: AddressDataService,
    private list: ListDataService
  ) { }

  ngOnInit(): void {
    this.deleteMessage = 'We are sad to see you go! Are you sure that you want to delete your profile?';
    this.retrace = 0;
  }

  delete(){
    var Data = this.data.getData();
    console.log(Data)
    this.profile.DELETE(Data['token']).subscribe(data => {});
    this.category.clearData();
    this.address.clearData();
    this.list.clearData();
    this.router.navigate(['/']);
  }

  stay(){
    this.deleteMessage = 'Thank you for giving us another chance! Would you like to return to your homepage?';
    this.retrace = 1;
  }

  return(){
    this.router.navigate(['/main']);
  }
}
