import { Component, OnInit } from '@angular/core';
import { CommentsService } from '../services/comments.service';
import { Router } from '@angular/router';
import { DataService } from '../data/data.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  token: string;

  constructor(
    private commentService: CommentsService,
    private router: Router,
    private Data: DataService
  ) { }

  ngOnInit(): void {
    this.token = this.Data.getData().token;
  }

  onSubmit(name, info, comment)
  {
    this.commentService.POSTComment(name, info, comment).subscribe(data =>{
      if(this.token != null)
      {
        this.router.navigate(['/main']);
      }
      else
      {
        this.router.navigate(['/']);
      }
    });
  }

}
