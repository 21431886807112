<img src="../../assets/logo/final.jpg" alt="logo" style="width: 40%" class="center">
<h1 class="pad">About Eats Picker</h1>
<p>Eats Picker is a site dedicated to taking the descision out of where to eat.
    The need for such a site was realized by its founder in the spring of 2019
    when he and his girlfriend could not decide on a restaurant to eat at. In 
    June of 2019 work began to construct a site which took on the great burden
    of one of life's great decisions... where do you want to eat? Eats Picker
    is constantly looking to improve its service so if you have a comment, 
    question, or suggestion, feel free to contact us via the "Email Us" link 
    located below.
</p>