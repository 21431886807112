import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryListService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  getCategoryList(token)
  {
    const url = this.baseURL + "getCategoryList"

    const options = 
    {
      headers: new HttpHeaders().set("token", token)
    }

    return this.http.get(url, options).pipe();
  }

  updateCategoryList(token, category1, category2, category3, category4, category5, category6, category7, category8, category9, category10, category11, category12, category13, category14, category15, category16, category17, category18, category19, category20, category21, category22, category23, category24, category25)
  {
    const url = this.baseURL + "updateCategoryList"
    var cat1;
    var cat2;
    var cat3;
    var cat4;
    var cat5;
    var cat6;
    var cat7;
    var cat8;
    var cat9;
    var cat10;
    var cat11;
    var cat12;
    var cat13;
    var cat14;
    var cat15;
    var cat16;
    var cat17;
    var cat18;
    var cat19;
    var cat20;
    var cat21;
    var cat22;
    var cat23;
    var cat24;
    var cat25;

      for(var i = 1; i <= 25; i++)
      {
        if(eval('category' + i + '!=undefined'))
        {
          eval('cat' + i + '=category' + i);
        }
        else
        {
          eval('cat' + i + '=null');
        }
      }
  
      var body = JSON.stringify({"token": token, "category1": cat1, "category2": cat2, "category3": cat3, "category4": cat4, "category5": cat5, "category6": cat6, "category7": cat7, "category8": cat8, "category9": cat9, "category10": cat10, "category11": cat11, "category12": cat12, "category13": cat13, "category14": cat14, "category15": cat15, "category16": cat16, "category17": cat17, "category18": cat18, "category19": cat19, "category20": cat20, "category21": cat21, "category22": cat22, "category23": cat23, "category24": cat24, "category25": cat25})
  
      const options = 
      {
        headers: new HttpHeaders().set("Content-Type", "application/json")
      }

      return this.http.put(url, body, options).pipe();
    }
}