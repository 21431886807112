import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup} from '@angular/forms';
import { DataService } from '../data/data.service';
import { CategoryDataService } from '../data/categoryData.service';
import { CategoryListService } from '../services/category-list.service';
import { AddressDataService } from '../data/addressData.service';
import { ListService } from '../services/list.service';
import { ListDataService } from '../data/listData.service';
import { AddressListService } from '../services/address-list.service';

export interface FavLists {
  favLists: string;
}

export interface List {
  nickname: string;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
  item6: string;
  item7: string;
  item8: string;
  item9: string;
  item10: string;
  item11: string;
  item12: string;
  item13: string;
  item14: string;
  item15: string;
  item16: string;
  item17: string;
  item18: string;
  item19: string;
  item20: string;
  item21: string;
  item22: string;
  item23: string;
  item24: string;
  item25: string;
}

@Component({
  selector: 'app-list-general',
  templateUrl: './list-general.component.html',
  styleUrls: ['./list-general.component.scss']
})
export class ListGeneralComponent implements OnInit {
  loginFlag: number;
  form: FormGroup;
  tick = false;
  restaurantName: string;
  restaurantSite: string;
  search: string;
  addressMessage: string;
  catRes: string;
  catList = [];
  favList = [];
  favPick: string;
  listDisplay = 1;
  listIndex: number;
  listChoice: number;
  listPick: List;
  listMessage: string;
  defaultList: string;
  hintMessage: string;
  categoryMessage: string;
  updateAddress: number;
  updateAddressText: string;

  listData: List[] = [];

  constructor(
    private data: DataService,
    private list: ListDataService,
    private listList: ListService) { }

  submit(){
  }

  ngOnInit(): void {
    var token = this.data.getData().token;
    if(token == null)
    {
      this.setListChoice(1);
      this.loginFlag = 0;
    }
    else
    {
      this.loginFlag = 1;
        this.listList.getList(token).subscribe(<Array>(listData) => {
          this.list.setListData(listData.length, listData[0], listData[1], listData[2], listData[3], listData[4], listData[5], listData[6], listData[7], listData[8], listData[9]);
      });
      this.setListChoice(0);
      this.setList();
      if(this.listData[0] != undefined)
      {
        this.defaultList = this.listData[0].nickname;
      }
    }
  }

  clearTextbox(text)
  {
   text.value = '';
  }


  grabRandom(len){
    var multiplier = len;
    var random = Math.floor(Math.random() * multiplier);

    return random;
  }

  toggleListChoice(){
    if(this.listChoice == 1 && this.list.getListData().length > 0){
      this.listChoice = 2;
      this.setListPicked(this.defaultList);
      this.listMessage = 'Enter New List';
      this.hintMessage = 'Choose a list for us to pick from or select a different way for us to choose your next meal';
    }
    else if(this.listChoice == 2){
      this.listChoice = 1;
      this.clearFavList();
      this.listMessage = 'Pick Saved List';
      this.hintMessage = 'Enter restaurants below to add to your list or select a different way for us to choose your next meal';
    }
    else{
      this.listChoice = 1;
      this.hintMessage = 'You dont have any saved lists. Please make a list and save it first or select a different way for us to choose your next meal';
    }
  }

  saveList(nickname){
    var currentList = this.favList;
    var item1 = null;
    var item2 = null;
    var item3 = null;
    var item4 = null;
    var item5 = null;
    var item6 = null;
    var item7 = null;
    var item8 = null;
    var item9 = null;
    var item10 = null;
    var item11 = null;
    var item12 = null;
    var item13 = null;
    var item14 = null;
    var item15 = null;
    var item16 = null;
    var item17 = null;
    var item18 = null;
    var item19 = null;
    var item20 = null;
    var item21 = null;
    var item22 = null;
    var item23 = null;
    var item24 = null;
    var item25 = null;

    var index = 1;

    while(this.favList[index] != null)
    {
      eval('item' + index + '=this.favList[index]');
      index++;
    }

    this.listList.POST(nickname, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19, item20, item21, item22, item23, item24, item25, this.listIndex, this.data.getData()['token']).subscribe(data => {});
    this.listData.push(item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19, item20, item21, item22, item23, item24, item25)
  }

  setListHintMessage(){
    if(this.list.getListData().length == 0){
      this.hintMessage = 'Enter restaurants below to add to your list or select a different way for us to choose your next meal';
    }
    else{
      this.hintMessage = 'Choose a list for us to pick from or select a different way for us to choose your next meal';
    }
  }

  setListChoice(override){
    if(this.list.getListData().length == 0 || override == 1){
      this.listChoice = 1;
      this.listMessage = 'Pick Saved List';
      this.hintMessage = 'Enter restaurants below to add to your list or select a different way for us to choose your next meal';
    }
    else{
      this.listChoice = 2;
      this.listMessage = 'Enter New List';
      this.hintMessage = 'Choose a list for us to pick from or select a different way for us to choose your next meal';
    }
  }

  setList(){
    this.listData = [];
    this.listIndex = 0;
    var lists = this.list.getListData();

    var nextList = eval("lists.list1");
    var index = 1;
    while(nextList != null && nextList != 'null')
    {
        index++;
        var items1 = null;
        var items2 = null;
        var items3 = null;
        var items4 = null;
        var items5 = null;
        var items6 = null;
        var items7 = null;
        var items8 = null;
        var items9 = null;
        var items10 = null;
        var items11 = null;
        var items12 = null;
        var items13 = null;
        var items14 = null;
        var items15 = null;
        var items16 = null;
        var items17 = null;
        var items18= null;
        var items19 = null;
        var items20 = null;
        var items21 = null;
        var items22 = null;
        var items23 = null;
        var items24= null;
        var items25 = null;

        var itemIndex = 1;
        while(eval('nextList.item' + itemIndex + '!=null&&nextList.item' + itemIndex + '!=\'null\''))
        {
          eval('items'+itemIndex+'=nextList.item'+itemIndex);
          itemIndex++;
        }
        this.listData.push({nickname: nextList.nickname, item1: items1, item2: items2, item3: items3, item4: items4, item5: items5, item6: items6, item7: items7, item8: items8, item9: items9, item10: items10, item11: items11, item12: items12, item13: items13, item14: items14, item15: items15, item16: items16, item17: items17, item18: items18, item19: items19, item20: items20, item21: items21, item22: items22, item23: items23, item24: items24, item25: items25});
        nextList = eval("lists.list" + (index - 1));
    }
    this.listIndex = index;
  }

  setListPicked(listPicked){
    var possibleLists = this.list.getListData();
    var lists = eval("possibleLists.list1")

    var possibleNum = this.list.getListData()["length"];

    for(var i = 1; i <= possibleNum; i++)
    {
      if(lists.nickname == listPicked)
      {
        var itemNull = lists.item1;
        var count = 0;
        while(itemNull != null && itemNull != 'null' && itemNull != 'undefined')
        {
          count++;
          this.pushFavList(itemNull);
          var newItem = "lists.item" + (count + 1)
          itemNull = eval(newItem);
        };
        var newLists = "possibleLists.list" + (i + 1);
        lists = eval(newLists);
      }
      else
      {
        var newLists = "possibleLists.list" + (i + 1);
        lists = eval(newLists);
      }
    }
  }

  clearFavList(){
    this.favList.length = 0;
  }

  pushFavList(test){
    if(this.favList.length == 0)
    {
      this.favList.push(test);
    }
    else
    {
      var len = this.favList.length - 1;
      this.favList[len] = ((this.favList[len].replace(/,/gi, "")) + ',');
      this.favList.push(test);
    }
  }

  deleteItem(item)
  {
    var index = this.favList.indexOf(item);
    this.favList.splice(index, 1);
    var length = this.favList.length;
    if(length == 1)
    {
      this.favList[0] = this.favList[0].replace(/,/gi, "");
    }
    else if(length == index)
    {
      this.favList[index - 1] = this.favList[index - 1].replace(/,/gi, "");
    }
  }

  deleteSavedItem(nickname, item)
  {
    var possibleLists = this.list.getListData();
    var lists = eval("possibleLists.list1")

    var possibleNum = this.list.getListData()["length"];
    var id = null;

    for(var i = 1; i <= possibleNum; i++)
    {
      if(lists.nickname == nickname)
      {
        id = lists.id;
      }
      else
      {
        var newLists = "possibleLists.list" + (i + 1);
        lists = eval(newLists);
      }
    }
    var index = this.favList.indexOf(item);
    this.favList.splice(index, 1);
    var length = this.favList.length;
    if(length == 1)
    {
      this.favList[0] = this.favList[0].replace(/,/gi, "");

    }
    else if(length == index)
    {
      this.favList[index - 1] = this.favList[index - 1].replace(/,/gi, "");
    }
    this.listList.updateList(this.data.getData().token, nickname, id, this.favList[0], this.favList[1], this.favList[2], this.favList[3], this.favList[4], this.favList[5], this.favList[6], this.favList[7], this.favList[8], this.favList[9], this.favList[10], this.favList[11], this.favList[12], this.favList[13], this.favList[14], this.favList[15], this.favList[16], this.favList[17], this.favList[18], this.favList[19], this.favList[20], this.favList[21], this.favList[22], this.favList[23], this.favList[24]).subscribe(data => {});
  }

  toggleListUpdate(id)
  {
    if(document.getElementById('listNicknameUpdate').className == 'divVisible')
    {
      document.getElementById('listNicknameUpdate').className = 'divInvisible';
      this.listDisplay = 1;
    }
    else
    {
      var index;
      var possibleLists = this.list.getListData();
      var list = eval("possibleLists.list1")

      var possibleNum = this.list.getListData()["length"];

      for(var i = 1; i < possibleNum; i++)
      {
        if(list.nickname == id)
        {
          index = (i - 1);
          break;
        }
        list = eval("possibleLists.list" + (i + 1));
      }

      this.listDisplay = 3;
      document.getElementById('listNicknameUpdate').className = 'divVisible';
      (<HTMLInputElement>document.getElementById('updateListNickname')).value = id;
    }
  }

  toggleSaveListNickname()
  {
    if(document.getElementById('listNicknameSave').className == 'divVisible')
    {
      document.getElementById('listNicknameSave').className = 'divInvisible';
    }
    else
    {
      document.getElementById('listNicknameSave').className = 'divVisible';
    }
  }

  updateListName(nickname, oldName)
  {
    var possibleLists = this.list.getListData();
    var lists = eval("possibleLists.list1")

    var possibleNum = this.list.getListData()["length"];
    var id = null;

    var dataId = null;
    for(var i = 1; i <= possibleNum; i++)
    {
      if(lists.nickname == oldName)
      {
        dataId = i;
        id = lists.id;
      }
        var newLists = "possibleLists.list" + (i + 1);
        lists = eval(newLists);
    }
    var myList = eval('this.list.getListData().list' + dataId);
    var item1 = myList.item1;
    var item2 = myList.item2;
    var item3 = myList.item3;
    var item4 = myList.item4;
    var item5 = myList.item5;
    var item6 = myList.item6;
    var item7 = myList.item7;
    var item8 = myList.item8;
    var item9 = myList.item9;
    var item10 = myList.item10;
    var item11 = myList.item11;
    var item12 = myList.item12;
    var item13 = myList.item13;
    var item14 = myList.item14;
    var item15 = myList.item15;
    var item16 = myList.item16;
    var item17 = myList.item17;
    var item18= myList.item18;
    var item19 = myList.item19;
    var item20 = myList.item20;
    var item21 = myList.item21;
    var item22 = myList.item22;
    var item23 = myList.item23;
    var item24= myList.item24;
    var item25 = myList.item25;
    var varList = {id, nickname, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19, item20, item21, item22, item23, item24, item25};
    this.listList.updateList(this.data.getData().token, nickname, id, this.favList[0], this.favList[1], this.favList[2], this.favList[3], this.favList[4], this.favList[5], this.favList[6], this.favList[7], this.favList[8], this.favList[9], this.favList[10], this.favList[11], this.favList[12], this.favList[13], this.favList[14], this.favList[15], this.favList[16], this.favList[17], this.favList[18], this.favList[19], this.favList[20], this.favList[21], this.favList[22], this.favList[23], this.favList[24]).subscribe(data => {});
    this.list.addListData(dataId, varList);
    this.listData[dataId - 1] = varList;
    this.listDisplay = 1;
  }

  pickFavList(){
    var pick = this.grabRandom(this.favList.length);
    this.favPick = this.favList[pick].replace(/,/gi, "");
    this.listDisplay = 2;
  }

  deleteList(listPicked){
    var possibleLists = this.list.getListData();
    var lists = eval("possibleLists.list1")

    var possibleNum = this.list.getListData()["length"];
    var token = this.data.getData();

    for(var i = 1; i <= possibleNum; i++)
    {
      if(lists.nickname == listPicked)
      {
        this.listList.deleteList(token.token, lists.id, possibleNum, i).subscribe(data => {
          this.listList.getList(token.token).subscribe(<Array>(data) => {
            this.list.clearData();
            this.list.setListData(data.length, data[0], data[1], data[2], data[3], data[4], data[5], data[6], data[7], data[8], data[9]);
            this.setList();
          })
        });
        lists = eval("possibleLists.list" + (i+1));
      }
      else
      {
        lists = eval("possibleLists.list" + (i+1));
      }
    }
  }

  newList()
  {
    this.favPick = '';
    this.favList = [];
    this.listDisplay = 1;
  }
}
