export class Data{
    token: string;
    username: string;
    password: string;
    email: string;

    clear() {
        this.token = '';
        this.username = '';
        this.password = '';
        this.email = '';
    }
}