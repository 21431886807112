import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class LocalRestaurantsService {
  private baseUrl: string = "https://discover.search.hereapi.com/v1/discover?";
  private params: HttpParams;
  constructor(private http: HttpClient) { }

  get(lat, long, rad){
    const url = this.baseUrl;

    const options = 
    { 
      params: new HttpParams().set('in', 'circle:' + lat + ',' + long + ';r=' + rad)
        .set('limit', '100')
        .set('q', 'restaurant')
        .set('apiKey', 'Kaq0cneOgdo5mSLBNwNT6Zm-s5hH9GXPVjyooITlGio')
    };
    
    return this.http.get(url, options);
  }

  inDepthGet(address){
    const url = address;

    return this.http.get(url);
  }
}
