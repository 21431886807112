<!-- <div class="signInSignUpTop">
  <a [routerLink]="['/login']">signin /</a>
  <a [routerLink]="['/createUser']"> signup</a>
</div> -->

<div class="leftSide">
  <img src="../../assets/logo/Full Logo Transparent.png" alt="Eats Picker Logo" style="width: 80%" class="center">
  
  <h1>WE PICK THE FOOD SO YOU DON'T HAVE TO</h1>
  <p>{{ hintMessage }}</p>
  <app-address-general *ngIf="location == 1" align="center" style="padding-top: 2%"></app-address-general>
  <app-category-general *ngIf="location == 2" align="center" style="padding-top: 2%"></app-category-general>
  <app-list-general *ngIf="location == 3" align="center"  style="padding-top: 2%"></app-list-general>
</div>
<div class="rightSide">
  <img src="../../assets/images/food-1050813_1920.jpg" alt="Asian Food on a table" class="homeImage">
</div>

<div align="center" class="optionButtons"> 
  <button mat-raised-button class="select" id="1" (click)="locationToggle('1'); setAddressHintMessage();">Pick by Location</button>
  <button mat-raised-button class="select" id="2" (click)="locationToggle('2'); setCategoryHintMessage();">Pick a Category</button>
  <button mat-raised-button class="select" id="3" (click)="locationToggle('3'); setListHintMessage();">Pick by List</button>
</div>

<app-explanatory-footer></app-explanatory-footer>