import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { Data } from '../data/data.model';
import { DataService } from '../data/data.service';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  Token: string;
  Username: string;
  Password: string;
  Email: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private profile: ProfileService,
    private data: DataService
  ) { }

  ngOnInit(): void {
    if(this.data.getData().token == null)
    {
      this.router.navigate(['/login'])
    }
    else
    {
      this.Token = this.data.getData()["token"];
      this.Username = this.data.getData()["username"];
      this.Password = this.data.getData()["password"];
      this.Email = this.data.getData()["email"];
      (<HTMLInputElement>document.getElementById("username")).value = this.Username;
      (<HTMLInputElement>document.getElementById("password")).value = this.Password;
      (<HTMLInputElement>document.getElementById("email")).value = this.Email;
    }
  }

  onSubmit(uname, pword, email) {
    if(this.Username != uname){
      this.updateUsername(uname);
    }
    if(this.Password != pword){
      this.updatePassword(pword);
    }
    if(this.Email != email){
      this.updateEmail(email);
    }
  }

  updateUsername(username) {
    this.profile.PUTUser(this.Token, username).subscribe((data) =>{
      this.data.setUsername(data['username']);
    });
  }

  updatePassword(password) {
    this.profile.PUTPassword(this.Token, password).subscribe((data) =>{
      this.data.setPassword(data['password']);
    });
  }

  updateEmail(email) {
    this.profile.PUTEmail(this.Token, email).subscribe((data) =>{
      this.data.setEmail(data['email']);
    });
  }

  deleteUser(){
    this.router.navigate(['/deleteUser'])
  }
}
