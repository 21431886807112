import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddressListService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  createAddress(token, addressCount, nickname, streetAddress, city, zip, state, country, latitude, longitude)
  {
    const url = this.baseURL + "createAddress"

    var body = JSON.stringify({"token": token, "addressCount": addressCount, "nickname": nickname, "streetAddress": streetAddress, "city": city, "zip": zip, "state": state, "country": country, "latitude": latitude, "longitude": longitude})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.post(url, body, options).pipe();
  }

  getAddressList(token)
  {
    const url = this.baseURL + "getAddress"

    const options = 
    {
      headers: new HttpHeaders().set("token", token)
    }

    return this.http.get(url, options).pipe();
  }

  updateAddress(token, id, nickname, streetAddress, city, zip, state, country, latitude, longitude)
  {
    const url = this.baseURL + "updateAddress"

    var body = JSON.stringify({"token": token, "id": id,"nickname": nickname, "streetAddress": streetAddress, "city": city, "zip": zip, "state": state, "country": country, "latitude": latitude, "longitude": longitude})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  deleteAddress(token, addressId, addressCount, addressListId)
  {
    console.log(token, addressId, addressCount, addressListId);
    const url = this.baseURL + "deleteAddress"

    const options = 
    {
      headers: new HttpHeaders().set("token", String(token))
                                .set("addressId", String(addressId))
                                .set("addressCount", String(addressCount))
                                .set("addressListId", String(addressListId))
    }


    return this.http.delete(url, options).pipe();
  }
}