<div class="row">
    <div class="col-md-4" style="padding: 2%">
      <img src="../../assets/images/world-map-1577937.jpg" alt="map" class="center" style="max-height: 3rem;">
      <h2>Pick by Location</h2>
      <p>Pick by location gives you a restaurant to try based on your location, how far you would like to travel, 
        and how much you would like to spend. Use this feature to try new places in your area, or to find places 
        to eat in unfamiliar areas.</p>
    </div>

    <div class="col-md-4" style="padding: 2%">
      <img src="../../assets/images/todo-2103511_1280.png" alt="checklist" class="center" style="max-height: 3rem;">
      <h2>Pick by Category</h2>
      <p>Pick by category is a premade list of food categories. Uncheck categories you don't want, and use the add 
        button to add your own categories. Use this feature when you are having a hard time deciding what type of
        food to eat.</p>
    </div>

    <div class="col-md-4" style="padding: 2%">
      <img src="../../assets/images/steps-1496523_1280.png" alt="numbered list" class="center" style="max-height: 3rem;">
      <h2>Pick by List</h2>
      <p>Pick by list allows you to choose a restaurant at random when you have narrowed your choices but cannot 
        decide between them. Use this feature when you have a list of restaurants that you would like to choose 
        from but cannot decide between them.</p>
    </div>
  </div>