export class ListData{
    length: number;
    list1: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list2: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list3: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list4: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list5: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list6: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list7: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list8: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list9: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };
    list10: {
        id: number;
        nickname: string;
        item1: string;
        item2: string;
        item3: string;
        item4: string;
        item5: string;
        item6: string;
        item7: string;
        item8: string;
        item9: string;
        item10: string;
        item11: string;
        item12: string;
        item13: string;
        item14: string;
        item15: string;
        item16: string;
        item17: string;
        item18: string;
        item19: string;
        item20: string;
        item21: string;
        item22: string;
        item23: string;
        item24: string;
        item25: string;
    };


    clear() {
        this.length = null;
        this.list1 = null;
        this.list2 = null;
        this.list3 = null;
        this.list4 = null;
        this.list5 = null;
        this.list6 = null;
        this.list7 = null;
        this.list8 = null;
        this.list9 = null;
        this.list10 = null;
    }
}