import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//App modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AllMaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
//App components
import { PickerComponent } from './picker/picker.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { geocodeService } from './services/geocode.service';
import { LocalRestaurantsService } from './services/local-restaurants.service';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './data/data.service';
import { CategoryDataService } from './data/categoryData.service';
import { VerificationCodeComponent } from './verification-code/verification-code.component';

import {MatCheckboxModule} from '@angular/material/checkbox';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { ProfileComponent } from './profile/profile.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AddressDataService } from './data/addressData.service';
import { AboutComponent } from './about/about.component';
import { CommentComponent } from './comment/comment.component';
import { FAQComponent } from './faq/faq.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SendResetEmailComponent } from './send-reset-email/send-reset-email.component';
import { AddressGeneralComponent } from './address-general/address-general.component';
import { CategoryGeneralComponent } from './category-general/category-general.component';
import { ListGeneralComponent } from './list-general/list-general.component';
import { ExplanatoryFooterComponent } from './explanatory-footer/explanatory-footer.component';
import { APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    PickerComponent,
    FooterNavComponent,
    UserLoginComponent,
    UserCreateComponent,
    ProfileComponent,
    UserUpdateComponent,
    AboutComponent,
    CommentComponent,
    FAQComponent,
    PasswordResetComponent,
    SendResetEmailComponent,
    AddressGeneralComponent,
    CategoryGeneralComponent,
    ListGeneralComponent,
    ExplanatoryFooterComponent,
    VerificationCodeComponent
  ],
  exports: [AllMaterialModule],
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    AllMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    NgbModule
  ],
  providers: [
    FooterNavComponent,
    geocodeService,
    LocalRestaurantsService,
    AuthenticationService,
    DataService,
    CategoryDataService,
    AddressDataService,
    Location,
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
