import { Component, OnInit } from '@angular/core';
import { RecoveryEmailService } from '../services/recovery-email.service';

@Component({
  selector: 'app-send-reset-email',
  templateUrl: './send-reset-email.component.html',
  styleUrls: ['./send-reset-email.component.scss']
})
export class SendResetEmailComponent implements OnInit {

  email: string;
  recoveryMessage: string;
  recoveryShow: number;
  sendButton: string;
  emailText: string;

  constructor(
    private recovery: RecoveryEmailService,
  ) {}

  ngOnInit(): void {
    this.recoveryShow = 1;
    this.sendButton = "Send Reset Email";
    this.recoveryMessage = "Hi there! Did you forget your password? If you did, please enter your email below and we will help you reset it!";
  }

  sendEmail(email){
    this.email = email;
    this.recovery.POST(email).subscribe(data =>{
      this.recoveryShow = 0;
      this.sendButton = "Send Email Again";
      this.recoveryMessage = "Email sent. Please check your email and follow our instructions there. If you don't see an email from us, check your spam folder or click the button below again."
    });
  }
}
