import { Injectable } from '@angular/core';
import { ListData } from './listData.model';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class ListDataService {

  list: ListData = new ListData();

  constructor(private storage: SessionStorageService) { }

  getListData(): ListData{
    this.list.length = this.storage.retrieve("listLength");
    for(var i = 1; i <= this.list.length; i++)
    {
      var nextRetrieve = "this.list.list" + i + "=this.storage.retrieve(\"list" + i + "\");"
      eval(nextRetrieve);
    }
    return this.list;
  }

  setListData(length, list1, list2, list3, list4, list5, list6, list7, list8, list9, list10){
    this.storage.store('listLength', length);
    this.storage.store('list1', list1);
    this.storage.store('list2', list2);
    this.storage.store('list3', list3);
    this.storage.store('list4', list4);
    this.storage.store('list5', list5);
    this.storage.store('list6', list6);
    this.storage.store('list7', list7);
    this.storage.store('list8', list8);
    this.storage.store('list9', list9);
    this.storage.store('list10', list10);
  }

  addListData(index, list){
    var listIndex = 'list' + index;
    this.storage.store(listIndex, list);
  }

  clearData(): ListData{
    this.list.clear();
    this.setListData(null, null, null, null, null, null, null, null, null, null, null);
    return this.list;
  }
}
