import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DataService } from '../data/data.service';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})

export class PickerComponent implements OnInit {
  location: number;
  hintMessage: string;
  login: boolean;

  constructor(
    private routeLocation: Location,
    private data: DataService) { }

  ngOnInit(): void {
    this.location = 1;
    document.getElementById('1').focus();
    this.setAddressHintMessage();
    if(this.data.data.token != null)
    {
      this.login = true;
    }
    else
    {
      this.login = false;
    }
  }

  about(){
    this.routeLocation.path();
  }

  locationToggle(toggleNum)
  {
    if(toggleNum == this.location)
    {
      this.location = 0;
      toggleNum.blur();
    }
    else
    {
      this.location = toggleNum;
    }
    window.scroll(0, 300);
  }

  setCategoryHintMessage(){
    this.hintMessage = 'Uncheck the categories you don\'t want us to choose from or select a different way for us to choose your next meal'
  }

  setAddressHintMessage(){
    this.hintMessage = 'Enter restaurants below to add to your list or select a different way for us to choose your next meal';
  }

  setListHintMessage(){
      this.hintMessage = 'Enter restaurants below to add to your list or select a different way for us to choose your next meal';
  }
}