import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  POST(nickname, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19, item20, item21, item22, item23, item24, item25, listCount, token)
  {
    const url = this.baseURL + "createList"
    console.log(listCount)

    var body = JSON.stringify({"nickname": nickname, "item1": item1, "item2": item2, "item3": item3, "item4": item4, "item5": item5, "item6": item6, "item7": item7, "item8": item8, "item9": item9, "item10": item10, "item11": item11, "item12": item12, "item13": item13, "item14": item14, "item15": item15, "item16": item16, "item17": item17, "item18": item18, "item19": item19, "item20": item20, "item21": item21, "item22": item22, "item23": item23, "item24": item24, "item25": item25, "listCount": listCount, "token": token})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.post(url, body, options).pipe();
  }

  getList(token)
  {
    const url = this.baseURL + "getList"

    const options = 
    {
      headers: new HttpHeaders().set("token", token)
    }

    return this.http.get(url, options).pipe();
  }

  updateList(token, nickname, id, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19, item20, item21, item22, item23, item24, item25)
  {
    const url = this.baseURL + "updateList"

    var body = JSON.stringify({"token": token, "nickname": nickname, "id": id, "item1": item1, "item2": item2, "item3": item3, "item4": item4, "item5": item5, "item6": item6, "item7": item7, "item8": item8, "item9": item9, "item10": item10, "item11": item11, "item12": item12, "item13": item13, "item14": item14, "item15": item15, "item16": item16, "item17": item17, "item18": item18, "item19": item19, "item20": item20, "item21": item21, "item22": item22, "item23": item23, "item24": item24, "item25": item25})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  deleteList(id, listId, listCount, listListId)
  {
    console.log(id, listId, listListId, listCount)
    const url = this.baseURL + "deleteList"

    const options = 
    {
      headers: new HttpHeaders().set("token", String(id))
                                .set("listId", String(listId))
                                .set("listCount", String(listCount))
                                .set("listListId", String(listListId))
    }

    return this.http.delete(url, options).pipe();
  }
}