import { Component, OnInit } from '@angular/core';
import { CreateUserService } from '../services/create-user.service';
import { Data } from '../data/data.model';
import { DataService } from '../data/data.service';
import { RecoveryEmailService } from '../services/recovery-email.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  Data: Data;
  error: number;
  tooShort: number;
  noNumber: number;
  noMatch: number;
  noSpecial: number;

  constructor(
    private create: CreateUserService,
    private data: DataService,
    private email: RecoveryEmailService,
    private router: Router) { }

  ngOnInit(): void {
    this.error = 0;
    this.tooShort = 0;
    this.noNumber = 0;
    this.noMatch = 0;
    this.noSpecial = 0;
  }

  onSubmit(username, password1, password2, email){
    this.error = 0;
    this.tooShort = 0;
    this.noNumber = 0;
    this.noMatch = 0;
    this.noSpecial = 0;

    if(password1 == password2 && password1.length >= 6 && (password1.includes("0") || password1.includes("1") || password1.includes("2") || password1.includes("3") || password1.includes("4") || password1.includes("5") || password1.includes("6") || password1.includes("7") || password1.includes("8") || password1.includes("9")) && (password1.includes("@") || password1.includes("%") || password1.includes("+") || password1.includes("\\") || password1.includes("/") || password1.includes("'") || password1.includes("!") || password1.includes("#") || password1.includes("$") || password1.includes("^") || password1.includes("?") || password1.includes(":") || password1.includes(",") || password1.includes("(") || password1.includes(")") || password1.includes("{") || password1.includes("}") || password1.includes("[") || password1.includes("]") || password1.includes("~") || password1.includes("`") || password1.includes("-") || password1.includes("_") || password1.includes("."))){
      this.create.POST(username, password1, email).subscribe((data) => {

        this.data.setData(data['token'], username, password1.length, email);
        this.email.verifyEmail(username, data['resetCode']).subscribe((data) => {
        });

        this.router.navigate(['/verificationCode']);
      },
      (error) => {
        console.log(error.error.message)
          this.error = 1;
        }
      );
    }
    if(password1.length < 6){
      this.tooShort = 1;
    }
    if((password1.includes("0") || password1.includes("1") || password1.includes("2") || password1.includes("3") || password1.includes("4") || password1.includes("5") || password1.includes("6") || password1.includes("7") || password1.includes("8") || password1.includes("9")) == false){
      this.noNumber = 1;
    }
    if(password1 != password2){
      this.noMatch = 1;
    }
    if((password1.includes("@") || password1.includes("%") || password1.includes("+") || password1.includes("\\") || password1.includes("/") || password1.includes("'") || password1.includes("!") || password1.includes("#") || password1.includes("$") || password1.includes("^") || password1.includes("?") || password1.includes(":") || password1.includes(",") || password1.includes("(") || password1.includes(")") || password1.includes("{") || password1.includes("}") || password1.includes("[") || password1.includes("]") || password1.includes("~") || password1.includes("`") || password1.includes("-") || password1.includes("_") || password1.includes(".")) == false){
      this.noSpecial = 1;
    }
  }
}