<h1>Create an Account</h1>
<form align="center">
    <input type="text" #username placeholder="Username"/><br><br>
    <input type="password" #password1 placeholder="Password"/><br><br>
    <input type="password" #password2 placeholder="Confirm Password"/><br><br>
    <input type="email" #email placeholder="Email"/><br>
    <button type="submit" (click)="onSubmit(username.value, password1.value, password2.value, email.value)">Submit</button>
    <h3 *ngIf="error == 1">Something went wrong creating your profile, please try again or contact us at support@eatspicker.com</h3>
    <h3 *ngIf="tooShort == 1">Your password was too short, try using at least 6 characters</h3>
    <h3 *ngIf="noNumber == 1">Your password needs a number in it</h3>
    <h3 *ngIf="noMatch == 1">Your passwords don't match</h3>
    <h3 *ngIf="noSpecial == 1">Your password needs a special charaacter like a period, exlamation point, or question mark</h3>
</form>