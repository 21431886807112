import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PickerComponent } from './picker/picker.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { ProfileComponent } from './profile/profile.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { AboutComponent } from './about/about.component';
import { FAQComponent } from './faq/faq.component';
import { CommentComponent } from './comment/comment.component';
import { EmailVerifiedThanksComponent } from './email-verified-thanks/email-verified-thanks.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SendResetEmailComponent } from './send-reset-email/send-reset-email.component';
import { VerificationCodeComponent } from './verification-code/verification-code.component';


const routes: Routes = [
  { path: '', component: PickerComponent },
  { path: 'picker', component: PickerComponent },
  { path: 'login', component: UserLoginComponent },
  { path: 'createUser', component: UserCreateComponent },
  { path: 'deleteUser', component: ProfileComponent },
  { path: 'userUpdate', component: UserUpdateComponent },
  { path: 'about', component: AboutComponent },
  { path: 'FAQ', component: FAQComponent },
  { path: 'comment', component: CommentComponent },
  { path: 'emailVerifiedThanks/:username/:resetCode', component: EmailVerifiedThanksComponent },
  { path: 'passwordReset/:username/:resetCode', component: PasswordResetComponent },
  { path: 'sendResetEmail', component: SendResetEmailComponent },
  { path: 'verificationCode', component: VerificationCodeComponent },
  { path: '**', component: PickerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
