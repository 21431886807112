import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class geocodeService {
  private baseUrl: string = "https://geocode.search.hereapi.com/v1/geocode?";
  private params: HttpParams;
  constructor(private http: HttpClient) { }

  get(address){
    const url = this.baseUrl;

    var add = address.replace(/ /gi, "+");

    var newAddress = add.replace(/,/gi, "");
    

    const options = 
    { 
      params: new HttpParams().set('q', newAddress)
        .set('apiKey', 'Kaq0cneOgdo5mSLBNwNT6Zm-s5hH9GXPVjyooITlGio')
    };
    
    return this.http.get(url, options);
  }
}
