import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecoveryEmailService } from '../services/recovery-email.service';
import { DataService } from '../data/data.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  resetStep: number;
  error: number;
  tooShort: number;
  noNumber: number;
  noMatch: number;
  noSpecial: number;
  username: string;
  resetCode: string;

  constructor(
    private router: Router,
    private recovery: RecoveryEmailService,
    private data: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resetStep = 1;
    this.activatedRoute.paramMap.subscribe(params => {
      this.username = params.get('username');
      this.resetCode = params.get('resetCode');
    })
  }

  onSubmit(password1, password2){
    this.error = 0;
    this.tooShort = 0;
    this.noNumber = 0;
    this.noMatch = 0;
    this.noSpecial = 0;

    if(password1 == password2 && password1.length >= 6 && (password1.includes("0") || password1.includes("1") || password1.includes("2") || password1.includes("3") || password1.includes("4") || password1.includes("5") || password1.includes("6") || password1.includes("7") || password1.includes("8") || password1.includes("9")) && (password1.includes("@") || password1.includes("%") || password1.includes("+") || password1.includes("\\") || password1.includes("/") || password1.includes("'") || password1.includes("!") || password1.includes("#") || password1.includes("$") || password1.includes("^") || password1.includes("?") || password1.includes(":") || password1.includes(",") || password1.includes("(") || password1.includes(")") || password1.includes("{") || password1.includes("}") || password1.includes("[") || password1.includes("]") || password1.includes("~") || password1.includes("`") || password1.includes("-") || password1.includes("_") || password1.includes("."))){
      this.recovery.PUTPasswordReset(this.username, password1, this.resetCode).subscribe((data) => {

        this.data.setData(data['token'], data['username'], password1.length, data['email']);

        this.router.navigate(['/main']);
      },
      (error) => {
        console.log(error.error.message)
          this.error = 1;
        }
      );
    }
    if(password1.length < 6){
      this.tooShort = 1;
    }
    if((password1.includes("0") || password1.includes("1") || password1.includes("2") || password1.includes("3") || password1.includes("4") || password1.includes("5") || password1.includes("6") || password1.includes("7") || password1.includes("8") || password1.includes("9")) == false){
      this.noNumber = 1;
    }
    if(password1 != password2){
      this.noMatch = 1;
    }
    if((password1.includes("@") || password1.includes("%") || password1.includes("+") || password1.includes("\\") || password1.includes("/") || password1.includes("'") || password1.includes("!") || password1.includes("#") || password1.includes("$") || password1.includes("^") || password1.includes("?") || password1.includes(":") || password1.includes(",") || password1.includes("(") || password1.includes(")") || password1.includes("{") || password1.includes("}") || password1.includes("[") || password1.includes("]") || password1.includes("~") || password1.includes("`") || password1.includes("-") || password1.includes("_") || password1.includes(".")) == false){
      this.noSpecial = 1;
    }
  }
}
