import { Inject, Injectable } from '@angular/core';
import { AddressData } from './addressData.model';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class AddressDataService {

    address: AddressData = new AddressData();

    constructor(private storage: SessionStorageService){}

    getAddressData(): AddressData{
        this.address.length = this.storage.retrieve("addressLength");
        for(var i = 1; i <= this.address.length; i++)
        {
            var nextRetrieve = "this.address.address" + i + "=this.storage.retrieve(\"address" + i + "\");";
            eval(nextRetrieve);
        }
        return this.address;
    }

    setAddressData(length, address1, address2, address3, address4, address5, address6, address7, address8, address9, address10){
        this.storage.store('addressLength', length);
        this.storage.store('address1', address1);
        this.storage.store('address2', address2);
        this.storage.store('address3', address3);
        this.storage.store('address4', address4);
        this.storage.store('address5', address5);
        this.storage.store('address6', address6);
        this.storage.store('address7', address7);
        this.storage.store('address8', address8);
        this.storage.store('address9', address9);
        this.storage.store('address10', address10);
    }

    updateAddressData(index, address){
        var addressPlace = 'address' + index;
        this.storage.store(addressPlace, address);
    }

    clearData(): AddressData{
        this.address.clear();
        this.setAddressData(null, null, null, null, null, null, null, null,null, null, null);
        return this.address;
    }
}