import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { DataService } from '../data/data.service';
import { CategoryDataService } from '../data/categoryData.service';
import { CategoryListService } from '../services/category-list.service';

export interface Category {
  category: string;
}

@Component({
  selector: 'app-category-general',
  templateUrl: './category-general.component.html',
  styleUrls: ['./category-general.component.scss']
})
export class CategoryGeneralComponent implements OnInit {
  checkboxAdd: number;
  form: FormGroup;
  catRes: string;
  catList = [];

  categoryData: Category[] = [];


  hintMessage: string;
  categoryMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private data: DataService,
    private category: CategoryDataService,
    private categoryService: CategoryListService) { }

  onChange(category: string) 
  {
    if(this.catList.indexOf(category) == -1)
    {
      this.catList.push(category);
    }
    else
    {
      const index = this.catList.indexOf(category);
      this.catList.splice(index, 1);
    }
  }

  pickCheck(){
    this.checkboxAdd = 0;
    this.catRes = this.catList[this.grabRandom(this.catList.length)];
  }

  setCategories(){
    this.categoryData = [];
    this.catList = [];
    var cats = this.category.getCategoryData();

    var nextCat = eval("cats.category1");
    var index = 1;
    while(nextCat != 'null' && nextCat != null)
    {
        index++;
        this.categoryData.push({category: nextCat});
        this.catList.push(nextCat);
        nextCat = eval("cats.category" + index);
    }
  }

  updateCategories(){
    var cats = this.catList;

    var data = this.data.getData();

    this.category.setCategoryData(cats[1], cats[2], cats[3], cats[4], cats[5], cats[6], cats[7], cats[8], cats[9], cats[10], cats[11], cats[12], cats[13], cats[14], cats[15], cats[16], cats[17], cats[18], cats[19], cats[20], cats[21], cats[22], cats[23], cats[24], cats[25]);
    if(data.token != null)
    {
      this.categoryService.updateCategoryList(data.token, cats[1], cats[2], cats[3], cats[4], cats[5], cats[6], cats[7], cats[8], cats[9], cats[10], cats[11], cats[12], cats[13], cats[14], cats[15], cats[16], cats[17], cats[18], cats[19], cats[20], cats[21], cats[22], cats[23], cats[24], cats[25]).subscribe(data =>{});
    }
    this.setCategories();
    this.categoryMessage = 'Deleted unchecked categories from the database'
  }

  setCategoryHintMessage(){
    this.hintMessage = 'Uncheck the categories you don\'t want us to choose from or select a different way for us to choose your next meal'
  }

  submit(){
  }

  ngOnInit(): void {
    var token = this.data.getData().token;
    this.form = this.formBuilder.group({
      name: this.formBuilder.array([])
    });
    this.checkboxAdd = 0;
    if(token == null)
    {
      this.category.setCategoryData('American', 'Chinese', 'Italian', 'Pizza', 'Breakfast', 'Indian', 'Sushi', 'Seafood', 'Barbeque', 'Vegetarian', 'Thai', 'Sandwiches', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null', 'null');
      this.setCategories();
    }
    else
    {
      this.categoryService.getCategoryList(token).subscribe((categoryData) => {
        this.category.setCategoryData(categoryData[0]['category1'], categoryData[0]['category2'], categoryData[0]['category3'], categoryData[0]['category4'], categoryData[0]['category5'], categoryData[0]['category6'], categoryData[0]['category7'], categoryData[0]['category8'], categoryData[0]['category9'], categoryData[0]['category10'], categoryData[0]['category11'], categoryData[0]['category12'], categoryData[0]['category13'], categoryData[0]['category14'], categoryData[0]['category15'], categoryData[0]['category16'], categoryData[0]['category17'], categoryData[0]['category18'], categoryData[0]['category19'], categoryData[0]['category20'], categoryData[0]['category21'], categoryData[0]['category22'], categoryData[0]['category23'], categoryData[0]['category24'], categoryData[0]['category25'])});
      this.setCategories();
      this.hintMessage = 'Start by entering your current address below or select a different way for us to choose your next meal';
    }
  }

  addCheckbox(addedCheck)
  {
    this.categoryData.push({category: addedCheck});
    this.catList.push(addedCheck);

    var category1 = null;
    var category2 = null;
    var category3 = null;
    var category4 = null;
    var category5 = null;
    var category6 = null;
    var category7 = null;
    var category8 = null;
    var category9 = null;
    var category10 = null;
    var category11 = null;
    var category12 = null;
    var category13 = null;
    var category14 = null;
    var category15 = null;
    var category16 = null;
    var category17 = null;
    var category18 = null;
    var category19 = null;
    var category20 = null;
    var category21 = null;
    var category22 = null;
    var category23 = null;
    var category24 = null;
    var category25 = null;

    var data = this.data.getData();

    var categories = this.category.getCategoryData();

    var index = 1;
    
    while(eval('categories.category' + index) != 'null')
    {
      index++;
    }

    for(var i = 1; i < index; i++){
      eval('category' + i + '=categories.category' + (i));
    }

    eval('category' + (index) + '=addedCheck');
    console.log(category1)

    if(data.token != null)
    {
      this.categoryService.updateCategoryList(data.token, category1, category2, category3, category4, category5, category6, category7, category8, category9, category10, category11, category12, category13, category14, category15, category16, category17, category18, category19, category20, category21, category22, category23, category24, category25).subscribe(data => {});
    }
  }

  clearTextbox(text)
  {
   text.value = '';
  }
  
  addCheck()
  {
    if(this.checkboxAdd == 0)
    {
      this.checkboxAdd = 1;
    }
    else
    {
      this.checkboxAdd = 0;
    }
  }

  grabRandom(len){
    var multiplier = len;
    var random = Math.floor(Math.random() * multiplier);

    return random;
  }

}