import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Data } from '../data/data.model';
import { DataService } from '../data/data.service';
import { CategoryData } from '../data/categoryData.model';
import { CategoryListService } from '../services/category-list.service';
import { ListService } from '../services/list.service';
import { ListDataService } from '../data/listData.service';
import { CategoryDataService } from '../data/categoryData.service';
import { AddressListService } from '../services/address-list.service';
import { AddressDataService } from '../data/addressData.service';
import { Router } from '@angular/router';

export interface Category {
  category1: string;
  category2: string;
  category3: string;
  category4: string;
  category5: string;
  category6: string;
  category7: string;
  category8: string;
  category9: string;
  category10: string;
  category11: string;
  category12: string;
  category13: string;
  category14: string;
  category15: string;
  category16: string;
  category17: string;
  category18: string;
  category19: string;
  category20: string;
  category21: string;
  category22: string;
  category23: string;
  category24: string;
  category25: string;
}

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  Data: Data;
  error: number;
  CategoryData: CategoryData;
  category: Category;
  

  constructor(
    private authenticate: AuthenticationService,
    private router: Router,
    private listList: ListService,
    private categoryService: CategoryListService,
    private categoryData: CategoryDataService,
    private addressList: AddressListService,
    private address: AddressDataService,
    private list: ListDataService,
    private data: DataService) { }

  ngOnInit(): void {
    this.error = 0;
  }

  onSubmit(username, password){
    this.authenticate.GET(username, password).subscribe((data) => {
      this.data.setData(data['token'], data['username'], data['password'], data['email']);
      this.categoryService.getCategoryList(data['token']).subscribe((categoryData) => {
        this.categoryData.setCategoryData(categoryData[0]['category1'], categoryData[0]['category2'], categoryData[0]['category3'], categoryData[0]['category4'], categoryData[0]['category5'], categoryData[0]['category6'], categoryData[0]['category7'], categoryData[0]['category8'], categoryData[0]['category9'], categoryData[0]['category10'], categoryData[0]['category11'], categoryData[0]['category12'], categoryData[0]['category13'], categoryData[0]['category14'], categoryData[0]['category15'], categoryData[0]['category16'], categoryData[0]['category17'], categoryData[0]['category18'], categoryData[0]['category19'], categoryData[0]['category20'], categoryData[0]['category21'], categoryData[0]['category22'], categoryData[0]['category23'], categoryData[0]['category24'], categoryData[0]['category25']);
        this.addressList.getAddressList(data['token']).subscribe(<Array>(addressData) => {
          this.address.setAddressData(addressData.length, addressData[0], addressData[1], addressData[2], addressData[3], addressData[4], addressData[5], addressData[6], addressData[7], addressData[8], addressData[9]);
          this.listList.getList(data['token']).subscribe(<Array>(listData) => {
            this.list.setListData(listData.length, listData[0], listData[1], listData[2], listData[3], listData[4], listData[5], listData[6], listData[7], listData[8], listData[9]);
            this.router.navigate(['/']);
          });
        });
      });
    },
    (error) => {
      console.log(error.error.message)
      
      if(error.error.message == "Username invalid"){
        this.error = 1;
      }
      else if(error.error.message == "Password incorrect"){
        this.error = 2;
      }
      else if(error.error.message == "User needs to verify email"){
        this.error = 3;
      }
      else{
        this.error = 4;
      }
    });
  }
}
