import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecoveryEmailService } from '../services/recovery-email.service';

@Component({
  selector: 'app-email-verified-thanks',
  templateUrl: './email-verified-thanks.component.html',
  styleUrls: ['./email-verified-thanks.component.scss']
})
export class EmailVerifiedThanksComponent implements OnInit {
  
  username: string;
  resetCode: string;

  constructor(
    private router: ActivatedRoute,
    private recovery: RecoveryEmailService) { }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params =>{
      this.username = params.get('username');
      this.resetCode = params.get('resetCode');
      this.recovery.verifyEmail(this.username, this.resetCode).subscribe((data) =>{});
    })
  }
}
