<main>
    <h1>Login</h1>
    <form align="center">
        <input type="text" #username placeholder="Username"/><br><br>
        <input type="password" #password placeholder="Password"/><br>
        <a [routerLink]="['/sendResetEmail']" class="forgotPassword">Forgot Password?</a><br><br>
        <button type="submit" (click)="onSubmit(username.value, password.value)">Submit</button>
        <h3 *ngIf="error == 1">We can't find that username</h3>
        <h3 *ngIf="error == 2">That password isn't correct</h3>
        <h3 *ngIf="error == 3">Please verify your email first</h3>
        <h3 *ngIf="error == 4">Something went wrong on our end, please contact us at support@eatspicker.com</h3>
    </form>
</main>