import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  PUTUser(token, user)
  {
    const url = this.baseURL + "updateUsername"

    var body = JSON.stringify({"token": token, "username": user})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  PUTPassword(token, password)
  {
    const url = this.baseURL + "updatePassword"

    var body = JSON.stringify({"token": token, "password": password})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  PUTEmail(token, email)
  {
    const url = this.baseURL + "updateEmail"

    var body = JSON.stringify({"token": token, "email": email})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  DELETE(token)
  {
    console.log(token)
    const url = this.baseURL + "deleteUser"

    const options = 
    {
      headers: new HttpHeaders().set("token", token)
    }

    return this.http.delete(url, options).pipe();
  }
}