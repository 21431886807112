import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass:  PathLocationStrategy}]
})
export class AppComponent implements OnInit{
  title = 'eatsPicker';
  location: Location;
  constructor(
    location: Location,
    private router: Router) 
  { this.location = location; }

  ngOnInit(){
    var path = location.pathname;
    //path = path.slice(0, -1)
    this.router.navigateByUrl(path);
  }
}