import { Component, OnInit} from '@angular/core';
import { geocodeService } from '../services/geocode.service';
import { LocalRestaurantsService } from '../services/local-restaurants.service';
import { DataService } from '../data/data.service';
import { AddressDataService } from '../data/addressData.service';
import { AddressListService } from '../services/address-list.service';

export interface Address {
  id: number;
  nickname: string;
  streetAddress: string;
  city: string;
  zip: number;
  state: string;
  country: string;
  latitude: string;
  longitude: string;
}

@Component({
  selector: 'app-address-general',
  templateUrl: './address-general.component.html',
  styleUrls: ['./address-general.component.scss']
})
export class AddressGeneralComponent implements OnInit {
  location: number;
  sliderValue: number;
  restaurantName: string;
  restaurantSite: string;
  search: string;
  addressMessage: string;
  addressChoice: number;
  addressPick: Address;
  addressIndex: number;
  defaultAddress: Address;
  saveAddressOption: number;
  hintMessage: string;
  updateAddress: number;
  updateAddressText: string;
  radius: number;
  addressText: string;
  result: number;
  goBack: string;
  pickedNumber: number;
  addressData: Address[] = [];

  constructor(
    private geocode: geocodeService,
    private data: DataService,
    private address: AddressDataService,
    private addressList: AddressListService,
    private restaurant: LocalRestaurantsService) { }

  submit(){
  }

  ngOnInit(): void {
    this.pickedNumber = 0;
    var token = this.data.getData().token;
    if(token == null)
    {
      this.addressChoice = 1;
      this.search = "Search";
    }
    else
    {
      this.addressList.getAddressList(token).subscribe(<Array>(addressData) => {
        this.address.setAddressData(addressData.length, addressData[0], addressData[1], addressData[2], addressData[3], addressData[4], addressData[5], addressData[6], addressData[7], addressData[8], addressData[9]);
    });
      this.result = 0;
      this.search = 'Search';
      this.setAddressChoice(0);
      this.setAddressList();
      this.updateAddress = 0;
      if(this.addressData[0] != undefined)
      {
        this.defaultAddress = this.addressData[0];
      };
      this.saveAddressOption = 0;
      this.hintMessage = 'Start by entering your current address below or select a different way for us to choose your next meal';
    }
  }

  onChange(id){
    var possibleAddresses = this.address.getAddressData();
    var address = eval("possibleAddresses.address1");

    var possibleNum = this.address.getAddressData()["length"];

    var i = 1;
    while(address.nickname != id)    
    {
      i++;
      this.pickedNumber = i - 1;
      address = eval("possibleAddresses.address" + i)
    }
  }

  saveAddress(add, nickname){
    var nickname = nickname;
    var streetAddress: string;
    var city;
    var zip;
    var state;
    var country;
    var latitude;
    var longitude;
    var token;

    this.geocode.get(add)
      .subscribe(data => {
        streetAddress = data['items'][0]['address']['houseNumber'] + ' ' + data['items'][0]['address']['street'];
        city = data['items'][0]['address']['city'];
        zip = data['items'][0]['address']['postalCode'];
        state = data['items'][0]['address']['stateCode'];
        country = data['items'][0]['address']['countryCode'];
        latitude = data['items'][0]['position']['lat'];
        longitude = data['items'][0]['position']['lng'];
        token = this.data.getData().token;
        this.addressList.createAddress(token, this.addressIndex, nickname, streetAddress, city, zip, state, country, latitude, longitude).subscribe(data => {
          this.addressData.push({id: 1, nickname: data['nickname'], streetAddress: data['streetAddress'], city: data['city'], zip: data['zip'], state: data['state'], country: data['country'], latitude: data['latitude'], longitude: data['longitude']});
        });
      });

    this.addressIndex = this.addressIndex + 1;
  }

  clearTextbox(text)
  {
   text.value = '';
  }

  sliderUpdate()
  {
  }

  toggleAddressChoice(){
    if(this.addressChoice == 1 && this.address.getAddressData().length > 0){
      this.addressChoice = 2;
      this.addressMessage = 'Enter New Address';
    }
    else if(this.addressChoice == 2){
      this.addressChoice = 1;
      this.addressMessage = 'Pick Saved Address';
    }
    else{
      this.addressChoice = 1;
      this.search = "Search";
      this.result = 0;
    }
  }

  setAddressChoice(override){
    if(this.address.getAddressData().length == 0 || override == 1){
      this.addressChoice = 1;
      this.addressMessage = 'Pick Saved Address';
    }
    else{
      this.addressChoice = 2;
      this.addressMessage = 'Enter New Address';
    }
  }

  setAddressHintMessage(){
    if(this.address.getAddressData().length == 0){
      this.hintMessage = 'Enter your current address below or select a different way for us to choose your next meal'
    }
    else{
      this.hintMessage = 'Choose one of the saved addresses below or select a different way for us to choose your next meal'
    }
  }

  setAddressList(){
    this.addressData = [];
    var add = this.address.getAddressData();

    var nextAdd = eval("add.address1");
    var index = 1;
    while(nextAdd != null)
    {
        index++;
        this.addressData.push({id: index, nickname: nextAdd.nickname, streetAddress: nextAdd.streetAddress, city: nextAdd.city, zip: nextAdd.zip, state: nextAdd.state, country: nextAdd.country, latitude: nextAdd.latitude, longitude: nextAdd.longitude});
        nextAdd = eval("add.address" + index);
    }
    this.addressIndex = (index - 1);
  }


  setAddressPicked(addressPicked, val){
    this.addressText = addressPicked;
    this.radius = val;
    var possibleAddresses = this.address.getAddressData();
    var address = eval("possibleAddresses.address1")

    var possibleNum = this.address.getAddressData()["length"]; 

    for(var i = 1; i < possibleNum; i++)
    {
      if(address.nickname == addressPicked)
      {
        this.getLocalRestaurants(address.latitude, address.longitude, val);
        address = eval("possibleAddresses.address" + (i +1))
      }
      else
      {
        address = eval("possibleAddresses.address" + (i +1))
      }
    }
  }

  toggleAddressUpdate(id)
  {
    if(document.getElementById('updateAddressDiv').className == 'divVisible')
    {
      document.getElementById('updateAddressDiv').className = 'divInvisible';
      this.updateAddress = 0;
    }
    else{
      var index;
      var possibleAddresses = this.address.getAddressData();
      var address = eval("possibleAddresses.address1")

      var possibleNum = this.address.getAddressData()["length"];

      for(var i = 1; i < possibleNum; i++)
      {
        if(address.nickname == id)
        {
          index = (i - 1);
          break;
        }
        address = eval("possibleAddresses.address" + (i + 1));
      }

      this.updateAddress = 1;
      document.getElementById('updateAddressDiv').className = 'divVisible';
      (<HTMLInputElement>document.getElementById('updateNickname')).value = this.addressData[index].nickname;
      (<HTMLInputElement>document.getElementById("updateAddressText")).value = this.addressData[index].streetAddress + ', ' + this.addressData[index].city + ', ' + this.addressData[index].state + ' ' + this.addressData[index].zip;
    }
  }

  updateAddressPicked(nickname, id, newAddress)
  {
    console.log(newAddress)
    var nickname = nickname;
    var streetAddress: string;
    var city;
    var zip;
    var state;
    var country;
    var latitude;
    var longitude;
    var token;
    var index;

    var possibleAddresses = this.address.getAddressData();
    var addresses = eval("possibleAddresses.address1")

    var possibleNum = this.address.getAddressData()["length"];

    for(var i = 1; i < possibleNum; i++)
    {
      if(addresses.nickname == id)
      {
        index = i;
        break;
      }
      addresses = eval("possibleAddresses.address" + (i + 1));
    }

    this.geocode.get(newAddress)
      .subscribe(data => {
        streetAddress = data['items'][0]['address']['houseNumber'] + ' ' + data['items'][0]['address']['street'];
        city = data['items'][0]['address']['city'];
        zip = data['items'][0]['address']['postalCode'];
        state = data['items'][0]['address']['stateCode'];
        country = data['items'][0]['address']['countryCode'];
        latitude = data['items'][0]['position']['lat'];
        longitude = data['items'][0]['position']['lng'];
        token = this.data.getData().token;
        this.addressList.updateAddress(token, index, nickname, streetAddress, city, zip, state, country, latitude, longitude).subscribe(data => { });
        this.address.updateAddressData(index, data);
        this.addressData[(index - 1)] = ({id: index, nickname: nickname, streetAddress: streetAddress, city: city, zip: zip, state: state, country: country, latitude: latitude, longitude: longitude})      
        });
    this.updateAddress = 0;
  }

  deleteAddress(addressPicked){
    var possibleAddresses = this.address.getAddressData();
    var address = eval("possibleAddresses.address1")

    var possibleNum = this.address.getAddressData()["length"];
    var token = this.data.getData();

    for(var i = 1; i <= possibleNum; i++)
    {
      if(address.nickname == addressPicked)
      {
        this.addressList.deleteAddress(token.token, address.id, possibleNum, i).subscribe(data => {
          this.addressList.getAddressList(token.token).subscribe(<Array>(data) => {
            this.address.clearData();
            this.address.setAddressData(data.length, data[0], data[1], data[2], data[3], data[4], data[5], data[6], data[7], data[8], data[9]);
            this.setAddressList();
          })
        });
        address = eval("possibleAddresses.address" + (i+1));
      }
      else
      {
        address = eval("possibleAddresses.address" + (i+1));
      }
    }
  }

  getGeocode(add, val){
    var lat;
    var long;
    this.radius = val;
    this.geocode.get(add)
      .subscribe(data => {
        lat = data['items'][0]['position']['lat'];
        long = data['items'][0]['position']['lng'];
        this.getLocalRestaurants(lat, long, val);
      });
  }

  getLocalRestaurants(lat, long, val){
    val = val*1609;
    this.restaurant.get(lat, long, val)
      .subscribe(data => {
        var resList = [];
        for(var i = 0; i < data['items'].length; i++)
        {
          resList.push(data['items'][i]);
        }
        var pick = this.grabRandom(resList.length);
        this.getRestaurantData(resList[pick]);
      });
  }

  getRestaurantData(address){
        this.restaurantName = address['title'];
        this.restaurantSite = address['contacts'][0]['www'][0]['value'];
        this.search = 'Pick Again';
        if(this.data.getData().token != null && this.addressChoice == 1){
          this.saveAddressOption = 1;
        }
        else{
          this.addressChoice = 0;
          this.result = 1;
          this.goBack = "New Address"
        }
  }

  grabRandom(len){
    var multiplier = len;
    var random = Math.floor(Math.random() * multiplier);

    return random;
  }
}
