<div>
    <div *ngIf="addressChoice==1">
      <input type="text" id="address" #address placeholder="Street Address">
      <h6 class="unhidden" id="finePrint">*address must include town name.</h6>
      <h4 class="hidden" id="warnings"></h4>
      <p class="textBoxMatch">Range of Search:</p>
      <input type="range" min="1" max="100" value="10" #rad class="slider" (mousemove)="sliderUpdate()" (change)="sliderUpdate()">
      <p><span id="slideCount" class="textBoxMatch">{{rad.value}}</span> mi.</p>
      <button mat-raised-button class="eat" (click)="getGeocode(address.value, rad.value)">{{ search }}</button><br>
      <div  *ngIf="saveAddressOption==1">
        <input type="text" name="newNickname" #newNickname placeholder="New Address Nickname">
        <button mat-raised-button class="eat" (click)="saveAddress(address.value, newNickname.value)"><i class="fa fa-floppy-o"></i> </button><br>
      </div>
    </div>
    <div *ngIf="addressChoice==2">
      <select id="addressPicked" #addressPicked class="drop" (change)="onChange(addressPicked.value)" value="nicknamePicked">
        <option *ngFor="let add of addressData" #nicknamePicked>{{ add.nickname }}</option>
      </select>
      <button mat-raised-button class="controls" (click)="toggleAddressChoice()"><i class="fa fa-plus"></i>new</button>
      <button mat-raised-button class="controls"(click)="toggleAddressUpdate(addressPicked.value)"><i class="fa fa-edit"></i>edit</button>
      <button mat-raised-button class="controls"(click)="deleteAddress(addressPicked.value)"><i class="fa fa-trash-o"></i>delete</button><br>
      <h1>{{addressData[pickedNumber].streetAddress}}</h1>
      <div *ngIf="updateAddress==0">
        <p class="textBoxMatch">Range of Search:</p>
        <input type="range" min="1" max="100" value="10" #rad class="slider" (mousemove)="sliderUpdate()" (change)="sliderUpdate()">
        <p><span id="slideCount" class="textBoxMatch">{{rad.value}}</span> mi.</p>
        <button mat-raised-button class="eat" (click)="setAddressPicked(addressPicked.value, rad.value)">{{ search }}</button><br>
      </div>
      <div class="divInvisible" id="updateAddressDiv">
        <br>
        <input type="text" #updateNickname id="updateNickname"/><br><br>
        <input type="text" #updateAddressText id="updateAddressText"/><br>
        <button mat-raised-button class="controls" (click)="updateAddressPicked(updateNickname.value, addressPicked.value, updateAddressText.value)"><i class="fa fa-floppy-o"></i></button>
      </div>
    </div>
    <div *ngIf="result==1">
        <a class="href" href={{restaurantSite}}>{{ restaurantName }}</a><br>
        <button mat-raised-button class="eat" (click)="toggleAddressChoice();">{{ goBack }}</button>
        <button mat-raised-button class="eat" (click)="setAddressPicked(this.addressText, radius)">{{ search }}</button>
    </div>
</div>