import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  POSTComment(name, contactInfo, comment)
  {
    const url = this.baseURL + "comment"

    var body = JSON.stringify({"commenterName": name, "contactInfo": contactInfo, "comment": comment})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.post(url, body, options).pipe();
  }
}
