import { Inject, Injectable } from '@angular/core';
import { CategoryData } from './categoryData.model';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class CategoryDataService {

    category: CategoryData = new CategoryData();

    constructor(private storage: SessionStorageService){}
    
    getCategoryData(): CategoryData {
        this.category.category1 = this.storage.retrieve("category1");
        this.category.category2 = this.storage.retrieve("category2");
        this.category.category3 = this.storage.retrieve("category3");
        this.category.category4 = this.storage.retrieve("category4");
        this.category.category5 = this.storage.retrieve("category5");
        this.category.category6 = this.storage.retrieve("category6");
        this.category.category7 = this.storage.retrieve("category7");
        this.category.category8 = this.storage.retrieve("category8");
        this.category.category9 = this.storage.retrieve("category9");
        this.category.category10 = this.storage.retrieve("category10");
        this.category.category11 = this.storage.retrieve("category11");
        this.category.category12 = this.storage.retrieve("category12");
        this.category.category13 = this.storage.retrieve("category13");
        this.category.category14 = this.storage.retrieve("category14");
        this.category.category15 = this.storage.retrieve("category15");
        this.category.category16 = this.storage.retrieve("category16");
        this.category.category17 = this.storage.retrieve("category17");
        this.category.category18 = this.storage.retrieve("category18");
        this.category.category19 = this.storage.retrieve("category19");
        this.category.category20 = this.storage.retrieve("category20");
        this.category.category21 = this.storage.retrieve("category21");
        this.category.category22 = this.storage.retrieve("category22");
        this.category.category23 = this.storage.retrieve("category23");
        this.category.category24 = this.storage.retrieve("category24");
        this.category.category25 = this.storage.retrieve("category25");
        return this.category;
    }

    setCategoryData(category1, category2, category3, category4, category5, category6, category7, category8, category9, category10, category11, category12, category13, category14, category15, category16, category17, category18, category19, category20, category21, category22, category23, category24, category25){
        this.storage.store('category1', category1);
        this.storage.store('category2', category2);
        this.storage.store('category3', category3);
        this.storage.store('category4', category4);
        this.storage.store('category5', category5);
        this.storage.store('category6', category6);
        this.storage.store('category7', category7);
        this.storage.store('category8', category8);
        this.storage.store('category9', category9);
        this.storage.store('category10', category10);
        this.storage.store('category11', category11);
        this.storage.store('category12', category12);
        this.storage.store('category13', category13);
        this.storage.store('category14', category14);
        this.storage.store('category15', category15);
        this.storage.store('category16', category16);
        this.storage.store('category17', category17);
        this.storage.store('category18', category18);
        this.storage.store('category19', category19);
        this.storage.store('category20', category20);
        this.storage.store('category21', category21);
        this.storage.store('category22', category22);
        this.storage.store('category23', category23);
        this.storage.store('category24', category24);
        this.storage.store('category25', category25);
    }

    clearData(): CategoryData {
        this.category.clear();
        return this.category;
    }
}