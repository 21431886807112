import { Injectable } from '@angular/core';
import { Data } from './data.model';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class DataService {

    data: Data = new Data();

    constructor(private storage: SessionStorageService){}

    getData(): Data {
        this.data.token = this.storage.retrieve('token');
        this.data.username = this.storage.retrieve('username');
        this.data.password = this.storage.retrieve('password');
        this.data.email = this.storage.retrieve('email');
        return this.data;
    }

    setData(token, uname, pword, emails){
        this.storage.store('token', token);
        this.storage.store('username', uname);
        this.storage.store('password', pword);
        this.storage.store('email', emails);
    }

    setUsername(uname){
        this.storage.store('username', uname);
    }

    setPassword(pword){
        this.storage.store('password', pword);
    }
    
    setEmail(email){
        this.storage.store('email', email);
    }

    clearData(): Data {
        this.data.clear();
        return this.data;
    }
}