 <div *ngIf="listChoice == 1">
    <div *ngIf="listDisplay == 1">
      <div *ngFor="let favorites of favList" class="keepItTogetherDiv">
        <button mat-raised-button class="controls" (click)="deleteItem(favorites)">&times;</button>
        <h1>{{ favorites }}&nbsp;&nbsp;</h1>
      </div>
      <br>
      <br>
      <input type="text" #favorites name="favotites" placeholder="Add a Restaurant">
      <button mat-raised-button class="controls" (click)="pushFavList(favorites.value); clearTextbox(favorites)"><i class="fa fa-plus"></i></button>
      <button *ngIf="loginFlag == 1" mat-raised-button class="controls" (click)="toggleSaveListNickname()"><i class="fa fa-floppy-o"></i></button>
      <br>
      <br>
      <br>
      <div class="divInvisible" id="listNicknameSave">
        <input type="text" #listNickname id="listNickname" placeholder="nickname">
        <button mat-raised-button class="controls" (click)="saveList(listNickname.value)"><i class="fa fa-floppy-o"></i></button>
      </div>
      <br>
      <br>
      <button mat-raised-button class="eat" (click)="pickFavList()">Pick</button>
      <br>
      <br>
      <br>
    </div>
    <div *ngIf="listDisplay == 2">
      <h1>{{ favPick }}</h1>
      <br>
      <br>
      <button mat-raised-button class="eat" (click)="newList()">New List</button>
      <button mat-raised-button class="eat" (click)="pickFavList()">Pick Again</button>
      <br>
      <br>
      <br>
    </div>
  </div>
  <div *ngIf="listChoice == 2">
    <select id="listPicked" class="drop" (change)="clearFavList(); setListPicked(listPicked.value)" #listPicked value="nicknamePicked">
      <option *ngFor="let list of listData" #nicknamePicked>{{ list.nickname }}</option>
    </select>
    <button mat-raised-button class="controls" (click)="toggleListChoice()"><i class="fa fa-plus"></i></button>
    <button mat-raised-button class="controls"(click)="toggleListUpdate(listPicked.value)"><i class="fa fa-edit"></i></button>
    <button mat-raised-button class="controls"(click)="deleteList(listPicked.value)"><i class="fa fa-trash-o"></i></button><br><br>
    <div *ngIf="listDisplay == 1">
      <div *ngFor="let favorites of favList" class="keepItTogetherDiv">
        <i class="fa fa-window-close"  style="color: #b90000; cursor: pointer;" (click)="deleteSavedItem(listPicked.value, favorites)"></i>
        <h1 class="favoritesList">{{ favorites }} &nbsp;</h1>
      </div>
      <br>
      <br>
      <input type="text" #favorites name="favotites" placeholder="Add a Restaurant">
      <button mat-raised-button class="controls" (click)="pushFavList(favorites.value); clearTextbox(favorites)"><i class="fa fa-plus"></i></button>
      <br>
      <button mat-raised-button class="eat" (click)="pickFavList()">Pick</button>
      <br>
      <br>
      <br>
    </div>
    <div *ngIf="listDisplay == 2">
      <h1>{{ favPick }}</h1>
      <br>
      <br>
      <button mat-raised-button class="eat" (click)="newList()">New List</button>
      <button mat-raised-button class="eat" (click)="pickFavList()">Pick Again</button>
      <br>
      <br>
      <br>
    </div>
    <div class="divInvisible" id="listNicknameUpdate">
      <input type="text" #updateListNickname id="updateListNickname"/>
      <button mat-raised-button class="eat" (click)="updateListName(updateListNickname.value, listPicked.value)"><i class="fa fa-floppy-o"></i></button>
    </div>
  </div>