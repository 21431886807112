export class AddressData{
    length: number;
    address1: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address2: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address3: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address4: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address5: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address6: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address7: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address8: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address9: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    address10: {
        id: number;
        nickname: string;
        streetAddress: string;
        city: string;
        zip: number;
        state: string;
        country: string;
        latitude: number;
        longitude: number;
    };
    

    clear() {
        this.length = null;
        this.address1 = null;
        this.address2 = null;
        this.address3 = null;
        this.address4 = null;
        this.address5 = null;
        this.address6 = null;
        this.address7 = null;
        this.address8 = null;
        this.address9 = null;
        this.address10 = null;
    }
}