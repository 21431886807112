import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/users";

  constructor(private http: HttpClient) { }

  GET(user, pswd)
  {
    const url = this.baseURL;

    const options = 
    {
      headers: new HttpHeaders().set("username", user)
                                .set("password", pswd)
    }

    return this.http.get(url, options).pipe();
  }
}