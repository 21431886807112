<form [formGroup]="form" (ngSubmit)="submit()">
  <div *ngFor="let categories of categoryData" class="quadUp">
      <mat-checkbox (click)="onChange(categories.category)" checked="true" color="primary">
        {{ categories.category }}
      </mat-checkbox>
  </div>
  <button *ngIf="checkboxAdd == 0" mat-raised-button class="controls" (click)="addCheck()"><i class="fa fa-plus"></i></button>
  <h1>{{ catRes }}</h1>
  <p>{{ categoryMessage }}</p>
  <div *ngIf="checkboxAdd == 1" align="center">
    <input type="text" #addedCheck name="checkText" class="hardRight" placeholder="Category to Add">
    <button mat-raised-button class="controls" (click)="addCheckbox(addedCheck.value); clearTextbox(addedCheck)"><i class="fa fa-plus"></i></button>
    <br>
    <br>
  </div>
  <button mat-raised-button class="eat" (click)="pickCheck()">Pick</button>
  <button mat-raised-button class="eat" (click)="updateCategories()"><i class="fa fa-trash-o" style="font-size: 200%;"></i></button>
</form>