import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/createUser";

  constructor(private http: HttpClient) { }

  POST(user, pswd, email)
  {
    const url = this.baseURL;

    var body = JSON.stringify({"username": user, "password": pswd, "email": email})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.post(url, body, options).pipe();
  }
}
