export class CategoryData{
    category1: string;
    category2: string;
    category3: string;
    category4: string;
    category5: string;
    category6: string;
    category7: string;
    category8: string;
    category9: string;
    category10: string;
    category11: string;
    category12: string;
    category13: string;
    category14: string;
    category15: string;
    category16: string;
    category17: string;
    category18: string;
    category19: string;
    category20: string;
    category21: string;
    category22: string;
    category23: string;
    category24: string;
    category25: string;

    clear() {
        this.category1 = '';
        this.category2 = '';
        this.category3 = '';
        this.category4 = '';
        this.category5 = '';
        this.category6 = '';
        this.category7 = '';
        this.category8 = '';
        this.category9 = '';
        this.category10 = '';
        this.category11 = '';
        this.category12 = '';
        this.category13 = '';
        this.category14 = '';
        this.category15 = '';
        this.category16 = '';
        this.category17 = '';
        this.category18 = '';
        this.category19 = '';
        this.category20 = '';
        this.category21 = '';
        this.category22 = '';
        this.category23 = '';
        this.category24 = '';
        this.category25 = '';
    }
}