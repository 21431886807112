import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecoveryEmailService {
  private baseURL: string = "https://eatspicker-api.com:3000/api/v1/";

  constructor(private http: HttpClient) { }

  POST(email)
  {
    const url = this.baseURL + "recoveryEmail"

    var body = JSON.stringify({"to": email})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.post(url, body, options).pipe();
  }

  PUTreVerify(email)
  {
    const url = this.baseURL + "reVerify"

    var body = JSON.stringify({"email": email})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  PUTPasswordReset(username, password, resetCode)
  {
    const url = this.baseURL + "passwordReset"

    var body = JSON.stringify({"username": username, "password": password, "resetCode": resetCode})

    const options = 
    {
      headers: new HttpHeaders().set("Content-Type", "application/json")
    }

    return this.http.put(url, body, options).pipe();
  }

  verifyEmail(username, resetCode)
  {
    const url = this.baseURL + "verify"

    const options = 
    {
      headers: new HttpHeaders().set("username", username)
                                .set("resetCode", resetCode)
    }

    return this.http.get(url, options).pipe();
  }
}